import 'trix';
import "@rails/actiontext"

document.addEventListener('trix-before-initialize', (event) => {
  Trix.config.toolbar.getDefaultHTML = msCustomToolbar;
  Trix.config.input.pickFiles = pickFiles;
  Trix.config.input.fileInputId = `trix-file-input-${Date.now().toString(16)}`
  Trix.controllers.EditorController.actions.attachFiles.perform = attachFiles;
  Trix.config.lang.urlPlaceholder = "https://example.com"
})

function msCustomToolbar() {
  const { lang } = Trix.config;

  return `
  <div class="trix-button-row @container border border-slate-200 w-full rounded-sm mb-2">
    <div class="flex flex-wrap"" data-trix-button-group=" text-tools">
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">
        <i class="fa_icon far fa-bold"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">
        <i class="fa_icon far fa-italic"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">
        <i class="fa_icon far fa-strikethrough"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">
        <i class="fa_icon far fa-heading"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">
        <i class="fa_icon far fa-quote-left"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-attribute="code" title="${lang.quote}" tabindex="-1">
        <i class="fa_icon far fa-code"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">
        <i class="fa_icon far fa-list-ol"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">
        <i class="fa_icon far fa-list"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">
        <i class="fa_icon far fa-outdent"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">
        <i class="fa_icon far fa-indent"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">
        <i class="fa_icon far fa-link"></i>
      </button>

      <button type="button"
        class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-action="trix--img#open" data-trix--img-target="btn"
        data-trix-key="e" title="Link an image to an external website" tabindex="-1" disabled>
        <i class="fa_icon far fa-image"></i>
        <i class="fa_icon far fa-link-simple"></i>
      </button>

      <div
        class="popover"
        data-controller="popover"
        data-popover-align-elem-value="#add_trix_token"
        data-popover-placement-value="bottom-end"
        data-action="turbo:morph@window->popover#reconnect">

        <button type="button"
          id="add_trix_token"
          class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300 hidden"
          data-trix--token-target="btn"
          data-action="popover#toggle"
          data-trix-key="t" title="Add a token" tabindex="-1">
          <i class="fa_icon far fa-coin-vertical"></i>
          <i class="fa_icon far fa-plus fa-xs -ml-0.5"></i>
        </button>

        <div class="popover-body popover--light max-w-lg" data-popover-target="body" data-trix--token-target="dropdownBody"></div>
      </div>

      <button type="button" class="p-2 @2xl:px-4 [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">
        <i class="fa_icon far fa-paperclip"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 hidden @lg:inline [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">
        <i class="fa_icon far fa-rotate-left"></i>
      </button>
      <button type="button" class="p-2 @2xl:px-4 hidden @lg:inline [&.trix-active]:bg-sky-200 disabled:text-gray-300"
        data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">
        <i class="fa_icon far fa-rotate-right"></i>
      </button>
    </div>
  </div>

  <div class="trix-dialogs" data-trix-dialogs>
    <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="trix-dialog__link-fields">
        <input type="url" name="href"
          class="trix-input trix-input--dialog"
          placeholder="${lang.urlPlaceholder}"
          aria-label="${lang.url}"
          data-trix-input>
        <div class="trix-button-group">
          <input type="button" class="trix-button trix-button--dialog" value="${lang.link}" data-trix-method="setAttribute">
          <input type="button" class="trix-button trix-button--dialog" value="${lang.unlink}"
            data-trix-method="removeAttribute">
        </div>
      </div>
    </div>

    <div class="trix-dialog" data-trix-dialog="imgLink" data-trix-dialog-attribute="imgLink" data-trix--img-target="dialog">
      <div class="text-xs text-slate-500 mb-1" data-trix--img-target="filename"></div>
      <div class="flex items-center justify-between gap-4">
        <input type="url" name="img_link"
          class="trix-input trix-input--dialog flex-grow"
          placeholder="${lang.urlPlaceholder}"
          aria-label="${lang.url}"
          data-trix--img-target="input"
          data-action=""
          data-trix-input>
        <div class="flex">
          <button type="button" class="btn text-sm border border-r-0 rounded-l rounded-r-none" data-action="trix--img#link">
            ${lang.link}
          </button>
          <button type="button" class="btn text-sm border rounded-r rounded-l-none" data-action="trix--img#unlink">
            ${lang.unlink}
          </button>
        </div>
      </div>
    </div>
  </div>`
}

function pickFiles(editorController) {
  const editorElement = editorController.element
  const input = document.createElement("input")

  input.setAttribute("type", "file")
  input.setAttribute("multiple", "true")
  input.setAttribute("hidden", "true")
  input.setAttribute("id", Trix.config.input.fileInputId)

  input.addEventListener("change", () => {
    editorController.insertFiles(input.files)
    input.remove()
  })

  editorElement.parentNode.appendChild(input)
  input.click()
  input.remove()
}

function attachFiles() {
  console.log('attachFiles');

  return Trix.config.input.pickFiles(this.editor)
}
