import 'trix'
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trix--token"
export default class extends Controller {
  static values = {
    tokenType: { type: String, default: 'text' }
  }

  static targets = ['btn', 'dropdownBody', 'template']

  connect() {
    this.btnTarget.classList.remove("hidden")
    this.dropdownBodyTarget.appendChild(this.templateTarget.content)
  }

  // https://github.com/basecamp/trix?tab=readme-ov-file#inserting-and-deleting-text
  add(event) {
    let value = event.currentTarget.dataset.value

    if (this.tokenType === 'contentAttachment') {
      var attachment = new Trix.Attachment({ content: value })
      this.editor.insertAttachment(attachment)
    } else if (this.tokenType === 'html') {
      this.editor.insertHtml(value)
    } else {
      this.editor.insertString(value)
    }
  }

  get editor() {
    return this.element.querySelector('trix-editor').editor
  }
}
