import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
  static values = { content: String, target: String, triggerTarget: String }

  connect() {
    tippy(this.target, { content: this.contentValue, zIndex: 10000, triggerTarget: this.triggerTarget })
  }

  get target() {
    if (this.hasTargetValue) {
      return this.targetValue
    } else {
      return this.element
    }
  }

  get triggerTarget() {
    if (this.hasTrigggerTargetValue) {
      return this.trigggerTargetValue
    } else {
      return this.element
    }
  }
}
