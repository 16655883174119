import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    attachmentsSize: Number
  }

  connect() {
    this.addEventListeners()
  }

  addEventListeners() {
    document.addEventListener("trix-file-accept", this.checkFile)
    document.addEventListener("trix-attachment-add", this.checkRemoteImage)
    document.addEventListener("trix-attachment-add", this.updateSize)
    document.addEventListener("trix-attachment-remove", this.updateSize)
  }

  checkFile = (event) => {
    if (this.invalidExtensions.includes(this.fileExtension(event.file.name))) {
      event.preventDefault()
      alert("File type not supported.")
    } else if (this.attachmentsSizeValue + event.file.size > 2097152) {
      event.preventDefault()
      alert("File too large. Attached file(s) must be less than 2MB total.")
    }
  }

  checkRemoteImage = (event) => {
    if (!event.attachment.file) {
      event.attachment.remove()
      alert("HTML image tags cannot be pasted. Please try attaching your image.")
    }
  }

  updateSize = (event) => {
    let filesize = event.attachment.getFilesize()

    if (event.type.includes("add")) {
      this.attachmentsSizeValue += filesize
    } else {
      this.attachmentsSizeValue -= filesize
    }
  }

  fileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2)
  }

  get invalidExtensions() {
    // https://postmarkapp.com/developer/user-guide/send-email-with-api#attachments
    // (see Forbidden File Types)
    return [
      "vbs", "exe", "bin", "bat", "chm", "com", "cpl", "crt", "hlp", "hta",
      "inf", "ins", "isp", "jse", "lnk", "mdb", "pcd", "pif", "reg", "scr",
      "sct", "shs", "vbe", "vba", "wsf", "wsh", "wsl", "msc", "msi", "msp",
      "mst"
    ]
  }

  get editor() {
    return this.element.querySelector('trix-editor').editor
  }
}
